// import all images from assets/images directory
import img01 from "../all-images/cars-img/innova.jpg";
import img02 from "../all-images/cars-img/swift.jpg";
import img03 from "../all-images/cars-img/ertiga.png";
import img04 from "../all-images/cars-img/innova_crysta.jpg";
import img05 from "../all-images/cars-img/honda_city.jpg";
import img06 from "../all-images/cars-img/etios.jpg";
import img07 from "../all-images/cars-img/marazzo.png";
import img08 from "../all-images/cars-img/xuv.png";
import img09 from "../all-images/cars-img/carens.jpg"

import int_swift from "../all-images/car_interior/swift_1.webp"
import int_swift_2 from "../all-images/car_interior/swift_2.webp"
import int_swift_3 from "../all-images/car_interior/swift_3.webp"

import  int_innova from "../all-images/car_interior/innova_1.jpg"
import  int_innova_2 from "../all-images/car_interior/innova_2.jpg"
import  int_innova_3 from "../all-images/car_interior/innova_3.jpg"

import int_ertiga from "../all-images/car_interior/ertiga_1.webp"
import int_ertiga_2 from "../all-images/car_interior/ertiga_2.webp"
import int_ertiga_3 from "../all-images/car_interior/ertiga_3.webp"

import int_honda from "../all-images/car_interior/honda_1.jpeg"
import int_honda_2 from "../all-images/car_interior/honda_2.jpeg"
import int_honda_3 from "../all-images/car_interior/honda_3.jpeg"

import int_etios from "../all-images/car_interior/etios_1.webp"
import int_etios_2 from "../all-images/car_interior/etios_2.webp"
import int_etios_3 from "../all-images/car_interior/etios_3.webp"

import int_marazzo from "../all-images/car_interior/marazzo.webp"
import int_marazzo_2 from "../all-images/car_interior/marazzo_2.webp"
import int_marazzo_3 from "../all-images/car_interior/marazzo_3.webp"

import int_xuv from "../all-images/car_interior/xuv_1.webp"
import int_xuv_2 from "../all-images/car_interior/xuv_2.webp"
import int_xuv_3 from "../all-images/car_interior/xuv_3.webp"

import carens from "../all-images/car_interior/carens_1.webp"
import carens_2 from "../all-images/car_interior/carens_2.webp"
import carens_3 from "../all-images/car_interior/carens_3.webp"



const carData = [
  {
    id: 1,
    brand: "Toyota",
    rating: 112,
    carName: "Innova",
    km8 : 2800,
    km8_extra : 200,
    hr12 : 4800,
    imgUrl: img01,
    images : [img01, int_innova, int_innova_2, int_innova_3],
    model: "Model 3",
    price: 17,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
    "airport taxi service, car taxi service, car taxi service in noida, local and outstation, nearby cab service, nearby taxi service, noida cab service, railwaystation taxi service, taxi service in noida, tour packages taxi service Airport Taxi Services, Car Rental Taxi Service, Car Taxi Services In Noida,   Local & Outstation Taxi Services, Noida Cab Service, Taxi Service In Noida, HMS Tour & Travels"
  },

  {
    id: 2,
    brand: "Maruti Suzuki",
    rating: 102,
    carName: "Swift Dzire",
    km8 : 1600,
    km8_extra : 100,
    hr12 : 2600,
    imgUrl: img02,
    images : [img02, int_swift, int_swift_2, int_swift_3],
    model: "Model-2022",
    price: 12,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
    "airport taxi service, car taxi service, car taxi service in noida, local and outstation, nearby cab service, nearby taxi service, noida cab service, railwaystation taxi service, taxi service in noida, tour packages taxi service Airport Taxi Services, Car Rental Taxi Service, Car Taxi Services In Noida,   Local & Outstation Taxi Services, Noida Cab Service, Taxi Service In Noida, HMS Tour & Travels"
  },

  {
    id: 3,
    brand: "Maruti Suzuki",
    rating: 132,
    carName: "Ertiga",
    km8 : 2300,
    km8_extra : 150,
    hr12 : 3200,
    imgUrl: img03,
    images : [img03, int_ertiga, int_ertiga_2, int_ertiga_3],
    model: "Model-2022",
    price: 14,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
    "airport taxi service, car taxi service, car taxi service in noida, local and outstation, nearby cab service, nearby taxi service, noida cab service, railwaystation taxi service, taxi service in noida, tour packages taxi service Airport Taxi Services, Car Rental Taxi Service, Car Taxi Services In Noida,   Local & Outstation Taxi Services, Noida Cab Service, Taxi Service In Noida, HMS Tour & Travels"
  },

  {
    id: 4,
    brand: "Toyota",
    rating: 102,
    carName: "Innova",
    km8 : 2800,
    km8_extra : 200,
    hr12 : 4800,
    imgUrl: img04,
    images : [img04, int_innova, int_innova_2, int_innova_3],
    model: "Model-2022",
    price: 17,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
    "airport taxi service, car taxi service, car taxi service in noida, local and outstation, nearby cab service, nearby taxi service, noida cab service, railwaystation taxi service, taxi service in noida, tour packages taxi service Airport Taxi Services, Car Rental Taxi Service, Car Taxi Services In Noida,   Local & Outstation Taxi Services, Noida Cab Service, Taxi Service In Noida, HMS Tour & Travels"
  },

  {
    id: 5,
    brand: "Honda",
    rating: 94,
    carName: "Honda City",
    km8 : 1800,
    km8_extra : 200,
    hr12 : 2800,
    imgUrl: img05,
    images : [img05, int_honda, int_honda_2, int_honda_3],
    model: "Model-2022",
    price: 14,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
    "airport taxi service, car taxi service, car taxi service in noida, local and outstation, nearby cab service, nearby taxi service, noida cab service, railwaystation taxi service, taxi service in noida, tour packages taxi service Airport Taxi Services, Car Rental Taxi Service, Car Taxi Services In Noida,   Local & Outstation Taxi Services, Noida Cab Service, Taxi Service In Noida, HMS Tour & Travels"
  },

  {
    id: 6,
    brand: "Toyota",
    rating: 119,
    carName: "Etios",
    km8 : 1600,
    km8_extra : 100,
    hr12 : 2600,
    imgUrl: img06,
    images : [img06, int_etios, int_etios_2, int_etios_3],
    model: "Model-2022",
    price: 13,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
    "airport taxi service, car taxi service, car taxi service in noida, local and outstation, nearby cab service, nearby taxi service, noida cab service, railwaystation taxi service, taxi service in noida, tour packages taxi service Airport Taxi Services, Car Rental Taxi Service, Car Taxi Services In Noida,   Local & Outstation Taxi Services, Noida Cab Service, Taxi Service In Noida, HMS Tour & Travels"
  },

  {
    id: 7,
    brand: "Mahindra",
    rating: 82,
    carName: "Marazzo",
    km8 : 2800,
    km8_extra : 200,
    hr12 : 4800,
    imgUrl: img07,
    images : [img07, int_marazzo, int_marazzo_2, int_marazzo_3],
    model: "Model 3",
    price: 16,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
    "airport taxi service, car taxi service, car taxi service in noida, local and outstation, nearby cab service, nearby taxi service, noida cab service, railwaystation taxi service, taxi service in noida, tour packages taxi service Airport Taxi Services, Car Rental Taxi Service, Car Taxi Services In Noida,   Local & Outstation Taxi Services, Noida Cab Service, Taxi Service In Noida, HMS Tour & Travels"
  },

  {
    id: 8,
    brand: "Mahindra",
    rating: 52,
    carName: "XUV 500",
    km8 : 2800,
    km8_extra : 200,
    hr12 : 4800,
    imgUrl: img08,
    images : [img08, int_xuv, int_xuv_2, int_xuv_3],
    model: "Model 3",
    price: 16,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
    "airport taxi service, car taxi service, car taxi service in noida, local and outstation, nearby cab service, nearby taxi service, noida cab service, railwaystation taxi service, taxi service in noida, tour packages taxi service Airport Taxi Services, Car Rental Taxi Service, Car Taxi Services In Noida,   Local & Outstation Taxi Services, Noida Cab Service, Taxi Service In Noida, HMS Tour & Travels"
  },
  {
    id: 8,
    brand: "Kia",
    rating: 52,
    carName: "Carens",
    km8 : 2800,
    km8_extra : 200,
    hr12 : 4800,
    imgUrl: img09,
    images : [img09, carens, carens_2, carens_3],
    model: "Model 3",
    price: 17,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
    "airport taxi service, car taxi service, car taxi service in noida, local and outstation, nearby cab service, nearby taxi service, noida cab service, railwaystation taxi service, taxi service in noida, tour packages taxi service Airport Taxi Services, Car Rental Taxi Service, Car Taxi Services In Noida,   Local & Outstation Taxi Services, Noida Cab Service, Taxi Service In Noida, HMS Tour & Travels"
  },
];

export default carData;
