import React, { useState } from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../styles/car-item.css";

const CarItem = (props) => {
  const { model, carName, automatic, speed, price, images } = props.item;
  const [currentIndex, setCurrentIndex] = useState(0);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 463, min: 0 }, // Adjusted breakpoint for smaller mobile screens
      items: 1,
    },
  };

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const fixedWidthImageStyle = {
    width: "200px", // Set to your desired fixed width
    height: "auto", // Ensure the aspect ratio is maintained
  };

  return (
    <Col lg="4" md="4" sm="6" className="mb-5">
      <div className="car__item">
        <Carousel
          responsive={responsive}
          ssr
          containerClass="carousel-container"
          itemClass="car__img"
          autoPlay={true}
          autoPlaySpeed={3000}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          infinite={true} // Set infinite to true for infinite scrolling
          afterChange={setCurrentIndex}
        >
          {images.map((imgUrl, index) => (
            <div key={index}>
              <img
                src={imgUrl}
                alt=""
                className="w-100"
                style={fixedWidthImageStyle} // Apply fixed width style for mobile devices
              />
            </div>
          ))}
        </Carousel>

        <div className="car__item-content mt-4">
          <h4 className="section__title text-center">{carName}</h4>
          <h6 className="rent__price text-center mt-">
            Rs. {price}.00 <span>/ KM</span>
          </h6>

          <div className="car__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
            <span className="d-flex align-items-center gap-1">
              <i className="ri-car-line"></i> {model}
            </span>
            <span className="d-flex align-items-center gap-1">
              <i className="ri-settings-2-line"></i> {automatic}
            </span>
            <span className="d-flex align-items-center gap-1">
              <i className="ri-timer-flash-line"></i> {speed}
            </span>
          </div>

          <button className="w-50 car__item-btn car__btn-rent">
            <Link to={`/cars/${carName}`}>Rent</Link>
          </button>

          <button className="w-50 car__item-btn car__btn-details">
            <Link to={`/cars/${carName}`}>Details</Link>
          </button>
        </div>
      </div>
    </Col>
  );
};

export default CarItem;
