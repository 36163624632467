const serviceData = [
  {
    id: 1,
    title: "City Transfer",
    icon: "ri-map-pin-2-line",
    desc: "Experience seamless city transfers with our reliable cab booking service. Reach your destination with comfort and ease, every time",
  },

  {
    id: 2,
    title: "Whole  City Tour",
    icon: "ri-community-line",
    desc: "Explore the entire city hassle-free with our comprehensive and convenient cab booking service. Discover the hidden gems and iconic landmarks on a seamless journey with us",
  },

  {
    id: 3,
    title: "Unlimited Miles Car Rental",
    icon: "ri-roadster-line",
    desc: "Where your journey knows no limits. Experience the freedom to explore with our cab booking agency, offering unlimited miles for an unforgettable adventure.",
  },

  {
    id: 4,
    title: "Fast & Easy Booking",
    icon: "ri-timer-flash-line",
    desc: " Your hassle-free ride is just a few clicks away. Book now for a seamless travel experience with our efficient cab booking service",
  },

  {
    id: 5,
    title: "Many Pickup Locations",
    icon: "ri-map-pin-line",
    desc: "Many Pickup Locations, One Unforgettable Ride! Book with us and enjoy hassle-free cab bookings from multiple pickup locations to your preferred destination",
  },

  {
    id: 6,
    title: "Airport Transfer",
    icon: "ri-flight-takeoff-line",
    desc: "Book our hassle-free airport transfer service for a smooth and comfortable journey to and from the airport",
  },
];

export default serviceData;
