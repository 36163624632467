import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";

import driveImg from "../assets/all-images/drive.jpg";
import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="About Us" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                Why Choose Us?
                </h2>

                <p className="section__description">
                Seamless Booking: Our user-friendly website and mobile app make booking a taxi effortless. Just a few taps, and you're on your way!
                </p>

                <p className="section__description">
                Personalized Service: We understand that every traveler is unique. That's why we offer customizable solutions to suit your preferences and requirements.
                </p>

                <p className="section__description">
                Customer-Centric Approach: Our dedicated customer support team is always ready to assist you with any queries or special requests.
                </p>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+91 89201 72328</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>


      <section>
        <Container>
          <Row>
          <Col lg="12" className="mb-5 text-center">
              <h2 className="section__title">Our Mission</h2>
              <p className="section__description">
              At HMS Tour & Travels, our mission is to redefine the way you travel. We aim to be your go-to travel companion, offering reliable and hassle-free transportation services that cater to your unique needs. Whether it's a short ride to the airport, a city tour, or a long-distance journey, we strive to make every mile memorable.
              </p>

            </Col>
            <Col lg="12" className="mb-5 text-center">
              <h2 className="section__title">Book Your Ride Today</h2>
              <p className="section__description">
              Experience the difference of a first-class taxi booking service. Whether you're traveling for business or pleasure, let us take care of your transportation needs. Book your ride today and embark on a journey of comfort, reliability, and convenience with HMS Tour & Travels. Happy travels!"
              </p>

            </Col>
            
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;
