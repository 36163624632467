import React from "react";
import { Container } from "reactstrap";
import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";

const PrivacyPolicy = () => {
  return (
    <Helmet title="Privacy Policy">
      <CommonSection title="Privacy Policy" />

      <section className="privacy__page-section">
        <Container>
          <h2 className="section__title">Our Commitment to Privacy</h2>
          <p className="section__description">
            At HMS Tour & Travels, we value your privacy and are committed to
            protecting your personal information. This Privacy Policy outlines
            how we collect, use, and safeguard your data when you use our
            website and services. By using our platform, you agree to the terms
            and practices described in this policy. We may update this policy
            from time to time, so we encourage you to review it regularly for
            any changes.
          </p>

          <h2 className="section__title">Information Collection</h2>
          <p className="section__description">
            We collect certain information when you book a ride or use our
            website or app. This may include your name, contact details,
            location, payment information, and other necessary details to
            provide you with our services. Rest assured that we only collect
            data that is relevant and necessary for the purpose of facilitating
            your travel requirements.
          </p>

          <h2 className="section__title">Information Usage</h2>
          <p className="section__description">
            The information we collect is primarily used to fulfill your booking
            requests, improve our services, and enhance your overall experience
            with us. We may also use your information to send you relevant
            updates, offers, and promotional materials. However, we will always
            provide you with the option to opt-out of such communications.
          </p>

          <h2 className="section__title">Data Security</h2>
          <p className="section__description">
            We have implemented robust security measures to protect your
            personal data from unauthorized access, misuse, or disclosure. Your
            information is stored securely, and we use encryption technologies
            to safeguard sensitive data.
          </p>

          <h2 className="section__title">Third-Party Services</h2>
          <p className="section__description">
            At times, we may share your information with third-party service
            providers who assist us in delivering our services. However, we
            ensure that these providers adhere to strict data protection
            standards and use your information solely for the purpose of
            assisting us.
          </p>

          <h2 className="section__title">Your Choices</h2>
          <p className="section__description">
            You have the right to access, modify, or delete your personal
            information. If you wish to do so or have any concerns about your
            data, please contact our customer support team.
          </p>
        </Container>
      </section>
    </Helmet>
  );
};

export default PrivacyPolicy;