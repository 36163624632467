import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";

import { useState } from "react";


const quickLinks = [
  {
    path: "/about",
    display: "About",
  },

  {
    path: "/privacy_policy",
    display: "Privacy Policy",
  },

  {
    path: "/cars",
    display: "Car Listing",
  },
  {
    path: "/blogs",
    display: "Blog",
  },

  {
    path: "/contact",
    display: "Contact",
  },
];



const Footer = () => {

  const instagramUrl = "https://www.instagram.com/hms_tour_travels/?igshid=MzNlNGNkZWQ4Mg==";
  const facebookUrl = "https://www.facebook.com/profile.php?id=100094454365111&mibextid=ZbWKwL";

  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setSubscribed(true);
    setEmail('');
  };

  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/home" className=" d-flex align-items-center gap-2">
                  <i class="ri-car-line"></i>
                  <span>
                    HMS  <br /> Tour & Travels
                  </span>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
            Welcome to HMS Tour & Travels, your trusted partner for seamless and memorable journeys. We take great pride in offering a premium taxi booking service that puts your comfort and convenience first. Whether you're a frequent traveler or seeking a one-time ride, we are committed to providing you with an unmatched travel experience.
            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Head Office</h5>
              <p className="office__info">C - Block, Sector 19, Noida, Uttar Pradesh (INDIA)</p>
              <p className="office__info">Phone: +91 8920172328</p>

              <p className="office__info">Email: hmstravels7@gmail.com</p>

              <p className="office__info">Office Time: 24x7 Available</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Payment Details</h5>
              <p className="office__info">Google Pay - 8920172328</p>
              <p className="office__info">Paytm - 8920172328</p>

              <p className="office__info">Phone Pay - 8920172328</p>
            </div>
            <p className="office__info">Follow us on</p>
            <div className="d-flex gap-2">
                <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram fa-lg fa-lg"></i>
                </a>
                <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-facebook fa-lg"></i>
                </a>
              </div>

          </Col>


          {/* <Col lg="3" md="4" sm="12">
      <div className="mb-4">
        <h5 className="footer__link-title">Newsletter</h5>
        <p className="section__description">Subscribe to our newsletter</p>
        {subscribed ? (
          <p className="success-message">Thank you for subscribing!</p>
        ) : (
          <form className="newsletter" onSubmit={handleFormSubmit}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleInputChange}
            />
            <button type="submit">
              <i className="ri-send-plane-line"></i>
            </button>
          </form>
        )}
      </div>
    </Col> */}

         

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i class="ri-copyright-line"></i>Copyright {year}, Developed by
                HMS Tour & Travels. All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
