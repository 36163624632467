import React, { useEffect } from "react";
import carData from "../assets/data/carData";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CarDetails = () => {
  const { slug } = useParams();
  const singleCarItem = carData.find((item) => item.carName === slug);

  const phoneNumber = "+918920172328";

  const handleCallButtonClick = () => {
    window.location.href = `tel:${encodeURIComponent(phoneNumber)}`;
  };

  const callButtonStyle = {
    backgroundColor: "#f9a826",
    color: "white",
    padding: "10px 20px",
    fontSize: "20px",
    fontWeight: "bold",
    borderRadius: "8px",
    cursor: "pointer",
    display: "block",
    margin: "0 auto", // Center the button horizontally
  };

  const pricingSectionStyle = {
    textAlign: "center",
    padding: "40px 0", // Add more padding to the pricing section
  };

  const pricingContainerStyle = {
    position: "relative",
    minHeight: "50vh", // Increase the minHeight for better mobile view
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const pricingCenterStyle = {
    maxWidth: "600px", // Limit the width of the pricing section for better mobile view
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [singleCarItem]);

  return (
    <Helmet title={singleCarItem.carName}>
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <Carousel showThumbs={false}>
                {singleCarItem.images.map((imgUrl, index) => (
                  <div key={index}>
                    <img src={imgUrl} alt={`Car ${index + 1}`} />
                  </div>
                ))}
              </Carousel>
            </Col>

            <Col lg="6">
              <div className="car__info">
                <h2 className="section__title">{singleCarItem.carName}</h2>

                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <h6 className="rent__price fw-bold fs-4">
                    Rs. {singleCarItem.price}.00 / KM
                  </h6>

                  <span className=" d-flex align-items-center gap-2">
                    <span style={{ color: "#f9a826" }}>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                    </span>
                    ({singleCarItem.rating} ratings)
                  </span>
                </div>

                <p className="section__description">
                  {singleCarItem.description}
                </p>

                <div
                  className=" d-flex align-items-center mt-3"
                  style={{ columnGap: "4rem" }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-roadster-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {singleCarItem.model}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-settings-2-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {singleCarItem.automatic}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-timer-flash-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {singleCarItem.speed}
                  </span>
                </div>

                <div
                  className=" d-flex align-items-center mt-3"
                  style={{ columnGap: "2.8rem" }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i class="ri-map-pin-line" style={{ color: "#f9a826" }}></i>{" "}
                    {singleCarItem.gps}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-wheelchair-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {singleCarItem.seatType}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-building-2-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    {singleCarItem.brand}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section style={pricingSectionStyle}>
        <Container>
          <Row>
            <Col xs="12">
              <div className="pricing-section mt-5 mb-5" style={pricingContainerStyle}>
                <div style={pricingCenterStyle}>
                  <h2 className="mb-4 fw-bold " style={{ color: "#000D6B", fontSize: "36px" }}>
                    Pricing
                  </h2>
                  <p style={{ color: "#000D6B", fontSize: "20px"}}>
                    {singleCarItem.carName} is available for hire at just {singleCarItem.price} Rupees per KM.
                  </p>
                  <p style={{ color: "#000D6B", fontSize: "24px" , fontWeight : "bold"}}>
                    80KM – Rs.&nbsp;{singleCarItem.km8} , LOCAL CHARGES | EXTRA HOUR – Rs.&nbsp;{singleCarItem.km8_extra} 
                  </p>
                  <p style={{ color: "#000D6B", fontSize: "24px" , fontWeight : "bold"}}>
                    200KM – Rs.&nbsp;{singleCarItem.hr12} , LOCAL CHARGES | FULL DAY ( 10 – 12 HOURS )
                  </p>

                  <p style={{ color: "#FF0000", fontSize: "16px" }}>
                    NOTE: SERVICE YOU HIRING IS OF 8 HOURS, IF SOMEHOW IT EXCEED MORE THAN 8 HOURS + 2 HOURS , THEN FULLDAY CHARGE WILL BE APPLICABLE.
                  </p>

                  <button style={callButtonStyle} onClick={handleCallButtonClick}>
                    Call Now to Book!
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarDetails;
