import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
// import aboutImg from "../../assets/all-images/cars-img/bmw-offer.jpg";
import aboutImg from "../../assets/all-images/cars-img/middle.jpg"
import aboutImg2 from "../../assets/all-images/cars-img/aboutImg1.jpg"
import aboutImg1 from "../../assets/all-images/cars-img/aboutImg2.jpg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const AboutSection = ({ aboutClass }) => {

  const images = [aboutImg, aboutImg1, aboutImg2];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
 
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "280px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">About Us</h4>
              <h2 className="section__title">Welcome to HMS Tour & Travels | Taxi Booking Service in Noida (call us @ 8920172328 for Taxi Booking)</h2>
              <p className="section__description">
              At HMS Tour and Travels at c block sec 19 noida, we pride ourselves on providing an unmatched taxi booking service that goes beyond the ordinary. We are committed to ensuring your safety, convenience, and satisfaction at every step of your travel experience. Whether you're a local resident or a traveler exploring new destinations, our goal is to be your reliable travel companion.
              </p>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Seamless Travel Experience
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> 24/7 Availability
                </p>
              </div>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Transparent Pricing
                </p>
               
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i>  Professional and Courteous Drivers
                </p>
              </div>
            </div>
          </Col>

          {/* <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col> */}
           <Col lg="6" md="6">
            <div className="about__img">
              <Slider {...settings}>
                {images.map((image, index) => (
                  <img key={index} src={image} alt="" className="w-100" />
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
