import React from "react";
import Slider from "react-slick";

import "../../styles/testimonial.css";

import ava01 from "../../assets/all-images/ava-1.jpg";
import ava02 from "../../assets/all-images/ava-2.jpg";
import ava03 from "../../assets/all-images/ava-3.jpg";
import ava04 from "../../assets/all-images/ava-4.jpg";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="testimonial py-4 px-3">
        <p className="section__description">
        Efficient and hassle-free cab service! As a frequent traveler, I've tried various cab booking services, but this one stands out. The drivers are always professional and arrive promptly. The vehicles are comfortable and well-equipped. The user interface of the website is intuitive, making booking a ride quick and easy. Whenever I need transportation, I know I can rely on this service for a smooth journey    </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava01} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Dheeraj Kumar</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
        Top-notch cab booking experience! I recently used this service for a family trip, and it was fantastic. The driver was friendly and knowledgeable, making the journey enjoyable. Booking was a breeze, and the support team was responsive to all my queries. Whether you need a ride to the airport or a sightseeing tour, this service won't disappoint. I'll be recommending it to all my friends and family!
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava02} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Shubham jha</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
        Absolutely impressed with the cab booking service! The entire process was seamless and convenient. From the user-friendly website to the professional and punctual drivers, every aspect exceeded my expectations. I highly recommend this cab service to anyone in need of a reliable and comfortable ride. Kudos to the team for providing an exceptional experience!
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava03} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Somdutt Pandey</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
        Excellent cab service! I frequently travel for business, and this cab booking service has become my go-to choice. The drivers are always on time, and the vehicles are clean and well-maintained. I appreciate the transparent pricing and the ability to track my ride in real-time. I feel safe and comfortable every time I use their service. I'm happy to have found a reliable partner for my travel needs
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava04} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Vikas Dubey</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Testimonial;
